export default [
  {
    key: 'liveDate',
    label: 'field.resultDate',
    rules: 'required',
    type: 'date',
  },
  {
    key: 'channelId',
    label: 'field.channel',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'channelBaccarate',
    selectionKey: 'id',
    selectionLabel: 'channelName',
  },
  {
    key: 'no',
    label: 'field.live',
    rules: 'required|integer',
    type: 'text',
  },
  {
    key: 'fourCard',
    label: 'field.fourCard',
    rules: 'required|integer',
    type: 'text',
  },
  {
    key: 'fiveCard',
    label: 'field.fiveCard',
    rules: 'required|integer',
    type: 'text',
  },
  {
    key: 'sixCard',
    label: 'field.sixCard',
    rules: 'required|integer',
    type: 'text',
  },
  {
    key: 'fightMatch',
    label: 'field.match',
    rules: 'required',
    type: 'text',
    cols: 12,
  },
]
