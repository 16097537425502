<template>
  <div>
    <div v-for="(data, idx) in value" :key="`row-${idx}`" class="match-group">
      <b-row>
        <b-col cols="12">
          <h4>
            {{ $t("general.matchWithNumber", { fight: data.no }) }}
          </h4>
        </b-col>
        <b-col md="6">
          <b-form-group class="fightResult">
            <validation-provider
              #default="{ errors }"
              :vid="`result-${idx}`"
              :name="$t('field.result')"
              rules="required"
            >
              <label>
                {{ $t("field.result") }}
              </label>
              <div>
                <b-form-radio-group
                  :id="`result-${idx}`"
                  :name="`result-${idx}`"
                  v-model="data.result"
                  buttons
                  button-variant="outline-primary"
                  :disabled="readonly"
                >
                  <b-form-radio
                    :class="`fightResult-${getFightResultById(option.value)}`"
                    :value="option.value"
                    v-for="option in resultOptions"
                    :key="option.value"
                  >
                    {{ $t(option.text) }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="data.result == 3">
          <b-form-group class="fightResult">
            <validation-provider
              #default="{ errors }"
              :vid="`sub-result-${idx}`"
              :name="$t('field.subResult')"
              rules="required"
            >
              <label>
                {{ $t("field.subResult") }}
              </label>
              <div>
                <b-form-radio-group
                  :id="`sub-result-${idx}`"
                  :name="`sub-result-${idx}`"
                  v-model="data.subResult"
                  buttons
                  button-variant="outline-primary"
                  :disabled="readonly"
                >
                  <b-form-radio
                    :class="`fightResult-${getFightResultById(option.value)}`"
                    :value="option.value"
                    v-for="option in subResultOptions"
                    :key="option.value"
                  >
                    {{ $t(option.text) }}
                  </b-form-radio>
                </b-form-radio-group>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col md="6">
        <b-button
          variant="danger"
          block
          @click="removeMatch()"
          class="mt-1"
          :disabled="this.value.length <= 1"
        >
          {{ $t("button.removeMatch") }}
        </b-button>
      </b-col>
      <b-col md="6">
        <b-button variant="primary" block @click="addMatch()" class="mt-1">
          {{ $t("button.addMatch") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BButton,
  },
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    resultOptions() {
      return [
        { text: "field.player", value: 2 },
        { text: "field.tie", value: 3 },
        { text: "field.banker", value: 1 },
      ];
    },
    subResultOptions() {
      return [
        { text: "field.player", value: 2 },
        { text: "field.banker", value: 1 },
      ];
    },
  },
  methods: {
    removeMatch() {
      if (this.value.length <= 1) {
        return;
      }

      let matches = [...this.value];
      matches.splice(matches.length - 1, 1);
      this.$emit("input", matches);
    },
    addMatch() {
      let matches = [...this.value];
      matches.push({
        no: matches.length + 1,
        result: null,
        subResult: null,
      });
      this.$emit("input", matches);
    },
    getFightResultById(id) {
      if (id === 1) {
        return "meron";
      } else if (id === 2) {
        return "wala";
      } else if (id === 3) {
        return "draw";
      } else if (id === 4) {
        return "cancel";
      }

      return "";
    },
  },
  setup() {},
};
</script>